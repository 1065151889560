<template>
  <div class="premium-overlay" @click.self="closeForm">
    <div class="premium-container">
      <h1>Buy Premium</h1>
      <div class="premium-content">
        <p>Get access to premium features by purchasing a subscription.</p>
        <form @submit.prevent="handleSubmit">
          <button type="submit" class="premium-button" :disabled="isSubmitting">
            Buy Premium
          </button>
        </form>
        <p v-if="message" class="info-message">{{ message }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';

export default {
  data() {
    return {
      isSubmitting: false,
      message: '',
      stripe: null,
    };
  },
  async created() {
    this.stripe = await loadStripe(
        'pk_live_51Npb9mGYUFZ9bd1e1giE3zYK6tHgi8V95DYEuQ82PTQFQVgnpbesWmLu5PXz8K4u45lM5gBjbsO9igLp9LsyDtXf00zqpJscy1'
        // 'pk_test_51Npb9mGYUFZ9bd1eMMeQgtRd5jZqpd14EbCaXcjIyIvRUfOISHrd9IGs8esjUN3QOwS90CpuNBRryooTr8Rt14h700SNo4Jxt9'
        ); // Replace with your Stripe public key
  },
  methods: {
    closeForm() {
      this.$emit('close');
    },
    async handleSubmit() {
  this.isSubmitting = true;
  const userId = localStorage.getItem('userId'); // Retrieve the user ID from local storage

  try {
    const response = await fetch('https://www.fapshort.com/create-checkout-session', {
      method: 'POST',
      body: JSON.stringify({
        user_id: userId,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const session = await response.json();

    const result = await this.stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      this.message = result.error.message;
    }
  } catch (error) {
    console.error('Error:', error);
    this.message = 'An error occurred. Please try again later.';
  } finally {
    this.isSubmitting = false;
    setTimeout(() => {
      this.message = '';
    }, 5000); // Reset the message after 5 seconds
  }
}

  },
};
</script>

<style scoped>
.premium-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.premium-container {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  max-width: 90%;
  text-align: center;
}

.premium-content p {
  margin-bottom: 20px;
}

.premium-button {
  background: #ff69b4;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 20px;
}

.premium-button:disabled {
  background: #ddd;
  cursor: not-allowed;
}

.info-message {
  color: green;
  font-size: 14px;
}
</style>
