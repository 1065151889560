<template>
  <div class="profile-page bg-dark text-white py-10 px-5">
    <h1 class="text-accent text-3xl font-bold mb-6 text-center">Edit Your Profile</h1>

    <!-- Profile Information Section -->
    <div class="profile-info bg-light-gray text-dark rounded-lg shadow-lg p-6 mx-auto max-w-3xl">
      <h2 class="text-accent text-xl font-bold mb-4 text-center">Profile Information</h2>

      <div class="profile-image-container text-center mb-6">
        <img v-if="profileImageUrl" :src="profileImageUrl" alt="Profile Image" class="profile-image" />
      </div>

      <form @submit.prevent="debounceUpdateProfile">
        <div class="mb-4">
  <label for="username" class="block text-md font-bold mb-2">Username</label>
  <input
    type="text"
    id="username"
    v-model="profile.username"
    class="input-field"
    placeholder="Enter your username"
  />
</div>
        <div class="mb-4">
          <label for="description" class="block text-md font-bold mb-2">Description</label>
          <textarea
            id="description"
            v-model="profile.description"
            class="input-field"
            placeholder="Add a short description about yourself..."
          ></textarea>
        </div>
        <div class="mb-4">
          <label for="onlyfans" class="block text-md font-bold mb-2">OnlyFans Link</label>
          <input
            type="url"
            id="onlyfans"
            v-model="profile.links.onlyfans"
            class="input-field"
            placeholder="https://onlyfans.com/yourprofile"
          />
        </div>
        <div class="mb-4">
          <label for="customLink" class="block text-md font-bold mb-2">Custom Link</label>
          <input
            type="url"
            id="customLink"
            v-model="profile.links.custom"
            class="input-field"
            placeholder="https://yourwebsite.com"
          />
        </div>
        <div class="mb-4">
          <label for="profileImage" class="block text-md font-bold mb-2">Profile GIF/Picture</label>
          <input type="file" id="profileImage" @change="handleProfileImageUpload" class="input-field" accept="image/*">
        </div>
        <button type="submit" class="button">
          Update Profile
        </button>
      </form>

       
    </div>

   <!-- Video Management Section -->
<!-- Video Management Section -->
<div class="video-management bg-light-gray text-dark rounded-lg shadow-lg p-6 mx-auto max-w-3xl mt-6">
  <h2 class="text-accent text-xl font-bold mb-4 text-center">Your Videos</h2>
  <div class="mb-6">
    <h3 class="text-md font-semibold mb-2">Upload a New Video</h3>
    <input type="file" @change="handleVideoUpload" accept="video/*" class="input-field mb-4" />
    <button @click="debounceUploadVideo" class="button">
      Upload Video
    </button>
  </div>

  <!-- Video Grid with Pagination -->
  <div class="video-grid">
    <h3 class="text-md font-semibold mb-2 text-center">Your Uploaded Videos</h3>
    <div class="grid-container">
      <div
        v-for="(video, index) in paginatedVideos"
        :key="video.id"
        class="video-item"
        @click="selectVideo(video)"
      >
        <div class="video-thumbnail">
          <video
            class="thumbnail-image"
            :src="video.url"
            muted
            preload="metadata"
            @loadeddata="captureThumbnail($event, video)"
          ></video>
          <p class="play-icon">▶</p>
        </div>
        <button @click.stop="deleteVideo(video.id, index)" class="delete-button">
          Delete
        </button>
      </div>
    </div>

    <!-- Pagination Controls -->
    <div class="pagination-controls">
      <button
        :disabled="currentPage === 1"
        @click="changePage(currentPage - 1)"
        class="pagination-button"
      >
        Previous
      </button>
      <span class="page-info">Page {{ currentPage }} of {{ totalPages }}</span>
      <button
        :disabled="currentPage === totalPages"
        @click="changePage(currentPage + 1)"
        class="pagination-button"
      >
        Next
      </button>
    </div>
  </div>
</div>


    <!-- Video Player Component -->
    <VideoPlayer v-if="selectedVideo" :video="selectedVideo" @close="closeVideoPlayer" />
     <div class="logout-section bg-light-gray text-dark rounded-lg shadow-lg p-6 mx-auto max-w-3xl mt-6">
      <button @click="logout" class="logout-button">
        Logout
      </button>
    </div>
  </div>
  
</template>

<script>
import axios from 'axios';
import VideoPlayer from './VideoPlayer.vue'; // Import VideoPlayer component
import _ from 'lodash'; // Import lodash

export default {
  name: 'ProfilePage',
  components: {
    VideoPlayer, // Register the component
  },
  props: {
    userVideos: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      profile: {
       username: '', 
        description: '',
        links: {
          onlyfans: '',
          custom: '',
        },
      },
      profileImageFile: null,
      profileImageUrl: null,
      videos: [],
      newVideoFile: null,
      selectedVideo: null, // To track the currently selected video for the player
       currentPage: 1, // Track the current page
    videosPerPage: 6,
    };
  },
  created() {
    this.fetchProfile();
  },
  computed: {
  // Calculate paginated videos based on the current page and videos per page
  paginatedVideos() {
    const start = (this.currentPage - 1) * this.videosPerPage;
    const end = start + this.videosPerPage;
    return this.userVideos.slice(start, end);
  },
  // Calculate the total number of pages
  totalPages() {
    return Math.ceil(this.userVideos.length / this.videosPerPage);
  },
},
emits: ["logout", ],
  methods: {
    logout() {
    this.$emit('logout');
  },
     debounceUpdateProfile: _.debounce(function() {
    this.updateProfile();
  }, 3000), // 3 seconds delay

  // Debounced uploadVideo method
  debounceUploadVideo: _.debounce(function() {
    this.uploadVideo();
  }, 3000), 
     changePage(page) {
    if (page > 0 && page <= this.totalPages) {
      this.currentPage = page;
    }
  },

  captureThumbnail(event, video) {
    const videoElement = event.target;
    videoElement.currentTime = 1;
    videoElement.pause();
    videoElement.style.objectFit = 'cover';
    video.thumbnail = videoElement.src;
  },
    async fetchProfile() {
      try {
        // const userId = localStorage.getItem('userId');
        console.log('user id in local storage', localStorage.getItem('userId'))
        const response = await axios.get(
          `https://www.fapshort.com/users/${localStorage.getItem('userId')}`,
          {
            headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
          }
        );

        if (response.data) {
          this.profile.username = response.data.username || ''; 
          this.profile.description = response.data.description || '';
          this.profile.links.onlyfans = response.data.onlyfans_link || '';
          this.profile.links.custom = response.data.other_links || '';
          this.profileImageUrl = response.data.profile_image || '';
        } else {
          console.warn('Profile data is not available.');
        }
      } catch (error) {
        console.error('Error fetching profile:', error);
        alert('Failed to fetch profile data. Please try again.');
      }
    },

    handleProfileImageUpload(event) {
      this.profileImageFile = event.target.files[0];
    },
    async updateProfile() {
      try {
        const formData = new FormData();
        formData.append('user[username]', this.profile.username);
        formData.append('user[description]', this.profile.description);
        formData.append('user[onlyfans_link]', this.profile.links.onlyfans);
        formData.append('user[other_links]', this.profile.links.custom);

        if (this.profileImageFile) {
          if (!(this.profileImageFile instanceof File)) {
            console.error('Profile image is not of type File:', this.profileImageFile);
            alert('Invalid file format. Please upload a valid image file.');
            return;
          }
          formData.append('user[profile_image]', this.profileImageFile);
        }

        const response = await axios.patch(
          `https://www.fapshort.com/users/${localStorage.getItem('userId')}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('authToken')}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        console.log('Profile updated:', response.data);
        alert('Profile updated successfully!');
      } catch (ex) {
        console.log('Error updating profile:', ex.response ? ex.response.data : ex);
        alert('Failed to update profile. Please try again.');
      }
    },

    handleVideoUpload(event) {
      this.newVideoFile = event.target.files[0];
    },

    async uploadVideo() {
      if (!this.newVideoFile) {
        alert('Please select a video to upload.');
        return;
      }
      const formData = new FormData();
      formData.append('video[file]', this.newVideoFile);
      formData.append('video[user_id]', localStorage.getItem('userId'));

      try {
        const response = await axios.post(
          'https://www.fapshort.com/videos/upload',
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('authToken')}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        console.log('Video uploaded:', response.data);
        this.videos.push({ title: this.newVideoFile.name, url: response.data.video.url });
        this.newVideoFile = null;
        alert('Video uploaded successfully!');
      } catch (error) {
        console.error('Error uploading video:', error);
        alert('Failed to upload video. Please try again.');
      }
    },

    // Method to select a video and show the video player
    selectVideo(video) {
      this.selectedVideo = video; // Set the selected video
    },

    // Method to close the video player
    closeVideoPlayer() {
      this.selectedVideo = null; // Reset the selected video to close the player
    },

    deleteVideo(videoId, index) {
      if (confirm('Are you sure you want to delete this video?')) {
        try {
          axios.delete(`https://www.fapshort.com/api/videos/${videoId}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
          });
          this.videos.splice(index, 1);
          alert('Video deleted successfully!');
        } catch (error) {
          console.error('Error deleting video:', error);
          alert('Failed to delete video. Please try again.');
        }
      }
    },
  },
};
</script>

<style scoped>

.logout-button {
  background-color: #d9534f; /* Red background for the logout button */
  color: white;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px; /* Spacing above the button */
  width: 100%; /* Make it full width */
}

.logout-button:hover {
  background-color: #c9302c; /* Darker red on hover */
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  width: 100%;
}

.video-grid {
  width: 100%;
  margin-top: 20px;
}

.video-item {
  background-color: #2c2c2c;
  border: 1px solid #444;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.video-item:hover {
  transform: translateY(-5px);
}

.video-thumbnail {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #333;
  height: 150px; /* Adjust as necessary */
}

.thumbnail-image {
  max-width: 100%;
  max-height: 100%;
}

.play-icon {
  position: absolute;
  font-size: 2em;
  color: #fff;
}

.profile-image-container {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.profile-page {
  background-color: #1a1a1a;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
}

.bg-light-gray {
  background-color: #2d3748;
}

.text-accent {
  color: #f53f85;
}

.text-dark {
  color: #ffffff;
}

.text-white {
  color: #ffffff;
}

.text-md {
  font-size: 1rem;
}

.profile-info,
.video-management {
  background-color: #2d3748;
  color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  margin-bottom: 30px;
}

.input-field {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #2d3748;
  color: #ffffff;
  margin-bottom: 12px;
}

.button {
  background-color: #f53f85;
  color: white;
  font-weight: bold;
  padding: 15px 20px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: inline-block;
  width: 100%;
  margin-top: 20px;
}

.button:hover {
  background-color: #e0427f;
}

.video-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.video-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}

button {
  cursor: pointer;
  transition: background-color 0.3s ease;
}
</style>
