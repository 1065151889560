<template>
  <div class="partner-page flex flex-col min-h-screen items-center justify-center touch-pan-y no-link-highlight">
    <div class="flex flex-col w-full max-w-xl mx-auto items-center justify-center" style="padding-bottom: calc(56px + env(safe-area-inset-bottom));">
      <div class="p-5 mobile-container text-center">
        <!-- <h1 class="text-3xl font-bold pb-8 text-white"></h1> -->
        <div class="mb-10">
          <!-- Updated to include the top text in a styled box -->
          <div class="text-container bg-white rounded-lg p-6 shadow-lg mb-6">
            <h2 class="text-2xl font-bold mb-4 text-center">Become a Partner</h2>
            <p class="text-base mb-4 last:mb-0 text-center">
              Upload your content to FapShort's audience of over 20 million monthly users and get clicks to your OnlyFans, Fansly, Linktree, Twitter, or any site you want!
            </p>
            <div class="mb-8 text-center">Combined, our Partners receive:</div>
            <div class="flex justify-center mb-8 gap-6">
              <div class="text-center">
                <div class="text-5xl font-bold mb-2 text-accent">107K</div>
                <div class="text-sm">Clicks to their links per day</div>
              </div>
            </div>
            <div class="mb-8 text-center font-bold">Join hundreds of other Creators such as:</div>
            <div class="flex justify-center items-center mb-8 gap-x-6 w-full">
              <a class="creator-link">
                <div class="image-container w-24 h-24 rounded-full overflow-hidden mx-auto mb-2">
                  <img class="object-cover w-full h-full" src="@/assets/1.jpg" alt="Your Submissive Doll">
                </div>
                <div class="text-sm font-bold text-center underline">Your Submissive Doll</div>
              </a>
              <a  class="creator-link">
                <div class="image-container w-24 h-24 rounded-full overflow-hidden mx-auto mb-2">
                  <img class="object-cover w-full h-full" src="@/assets/2.jpg" alt="Natural Nadia">
                </div>
                <div class="text-sm font-bold text-center underline">Natural Nadia</div>
              </a>
              <a class="creator-link">
                <div class="image-container w-24 h-24 rounded-full overflow-hidden mx-auto mb-2">
                  <img class="object-cover w-full h-full" src="@/assets/3.jpg" alt="Ginger4Play">
                </div>
                <div class="text-sm font-bold text-center underline">Ginger4Play</div>
              </a>
            </div>

            <div class="text-base mb-4 last:mb-0 text-center">
              <button @click="handleBecomePartnerClick" class="become-partner-button">Become a Partner today!</button>
            </div>
          </div>
        </div>

        <!-- Video Section -->
        <div class="mb-10">
          <div class="video-container">
            <video
                src="@/assets/partners-intro.mp4"
                playsinline
                autoplay
                loop
                class="rounded-xl"
            ></video>
          </div>
          <!-- <a href="/">
            <div class="flex justify-center items-center mb-8 gap-x-6 w-full">
              <button class="become-partner-button">Explore FapShort</button>
            </div>
          </a> -->
        </div>
        
        <!-- FAQ Section -->
        <div class="-mx-5 mb-10">
          <div class="mb-4" v-for="(faq, index) in faqs" :key="index">
            <!-- <button @click="toggleFaq(index)" class="faq-category w-full flex items-center justify-between border-b px-5 pb-5 border-accent-100">
              <div class="text-md text-white text-left">{{ faq.category }}</div>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon" class="text-accent-100 w-5 h-5">
                  <path stroke-linecap="round" stroke-linejoin="round" :d="faq.expanded ? 'M5 12h14' : 'm19.5 8.25-7.5 7.5-7.5-7.5'"></path>
                </svg>
              </div>
            </button> -->
            <div :class="['faq-content', faq.expanded ? 'expanded' : '']">
              <div class="text-bright">
                <div v-for="(item, idx) in faq.items" :key="idx">
                  <button class="faq-item w-full flex items-center justify-between px-5 py-3 bg-accent-700">
                    <div class="text-md text-white font-bold text-left">{{ item.question }}</div>
                    <div class="pl-4">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon" class="text-accent-50 w-3 h-3">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14"></path>
                      </svg>
                    </div>
                  </button>
                  <div :class="['faq-answer', item.expanded ? 'expanded' : '']">
                    <div class="text-accent-50 text-md">
                      <p v-for="(answer, ansIdx) in item.answers" :key="ansIdx">{{ answer }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
 
    
    <!-- Login Form Modal -->
    <LoginForm v-if="showLoginForm" @close="closeLoginForm" @login-success="handleLoginSuccess" />

  </div>
  <div class="tos-section bg-white rounded-lg p-6 shadow-lg mt-10">
  <h2 class="text-2xl font-bold mb-4 text-center">Terms of Service</h2>
  <div class="tos-content">
    <p>
      <strong>Notice of Non-Affiliation and Disclaimer</strong><br>
      We are not affiliated, associated, authorized, endorsed by, or in any way officially connected with TikTok, or any of its subsidiaries or its affiliates. The official TikTok website can be found at <a href="https://www.tiktok.com" target="_blank">https://www.tiktok.com</a>. The name TikTok, as well as related names, marks, emblems, and images, are registered trademarks of their respective owners.
    </p>
    <p><strong>Terms Of Service</strong> <br> <em>Last updated June 14, 2024</em></p>

    <h3>1. Acceptance</h3>
    <p>
      By using and/or visiting the Fapshort.com website (collectively, including but not limited to all Content, Uploads, and User Submissions available through Fapshort.com, the Website), you agree to the terms and conditions contained herein and the terms and conditions of Fapshort.com’s privacy policy incorporated herein, and all future amendments and modifications (collectively referred to as the ‘agreement’). By entering, you agree to be bound by these terms and conditions. If you do not agree to be bound by the terms and conditions contained herein, then do not use Fapshort.com.
    </p>
    <p>
      The terms and conditions of this Agreement are subject to change by Fapshort.com at any time in its sole discretion, and you agree to be bound by all modifications, changes, and/or revisions. If you do not accept to be bound by any modifications, changes, and/or revisions of this agreement, you may not use Fapshort.com. The terms and conditions contained herein apply to all users of Fapshort.com, whether a ‘visitor’ or a ‘member,’ and you are only authorized to use Fapshort.com if you agree to abide by all applicable laws and be legally bound by the terms and conditions of this Agreement.
    </p>

    <h3>2. Description</h3>
    <p>
      The Fapshort.com website allows for uploading, sharing, and general viewing of various types of content, allowing registered and unregistered users to share and view visual depictions of adult content, including sexually explicit images. In addition, Fapshort.com contains photographic/video content, information, and other materials posted/uploaded by users. Fapshort.com allows its users to view the Content and Website subject to the terms and conditions of this Agreement.
    </p>
    <p>
      Fapshort.com is for your personal use and shall not be used for any commercial endeavor except those specifically endorsed or approved by Fapshort.com. Any illegal and/or unauthorized use of Fapshort.com is prohibited, including but not limited to collecting usernames and email addresses for sending unsolicited emails or unauthorized framing or linking to the Fapshort.com website.
    </p>

    <h3>3. Access</h3>
    <p>
      In order to use this Website, you affirm that you are at least eighteen (18) years of age and/or over the age of majority in the jurisdiction you reside and from which you access the Website where the age of majority is greater than eighteen (18) years of age. If you are under the age of 18 and/or under the age of majority in the jurisdiction you reside and from which you access the Website, then you are not permitted to use the Website.
    </p>

    <h3>4. Conduct</h3>
    <p>
      You acknowledge and agree that you shall be responsible for your own User Submissions and the consequences of posting, uploading, publishing, transmitting, or otherwise making them available on Fapshort.com. You agree that you shall not (nor others using your account) post, upload, publish, transmit, or make available in any way on Fapshort.com content that is illegal, unlawful, harassing, harmful, threatening, tortuous, abusive, defamatory, obscene, libelous, invasive of one’s privacy, including but not limited to personal information, hateful, or racial.
    </p>
    <p>
      You also agree that you shall not post, upload, publish, transmit, or make available in any way on Fapshort.com software containing viruses or any other computer code, files, or programs designed to destroy, interrupt, limit the functionality of, or monitor, or persistently reside in or on any computer software or hardware or telecommunications equipment. You agree that you shall not (nor others using your account) post, upload, publish, transmit, or make available in any way on Fapshort.com content that is intentionally or unintentionally violating any applicable local, state, national, or international law, or any regulations or laws having the force of law where you reside and elsewhere, including but not limited to any laws or regulations relating to securities, privacy, and export control.
    </p>
    <p>
      You agree that Fapshort.com shall have the right to determine in its sole and unfettered discretion what action shall be taken in the event of any discovered or reported violation of the terms and conditions contained herein.
    </p>

    <h3>5. Intellectual Property</h3>
    <p>
      The Content contained on the Fapshort.com website, with the exception of User Submissions, including but not limited to the text, software, scripts, graphics, music, videos, photos, sounds, interactive features, and trademarks, service marks, and logos contained therein, are owned by and/or licensed to Fapshort.com, subject to copyright and other intellectual property rights under Swiss and foreign laws and international conventions. Content on the Website is provided to you AS IS for your information and personal use only and may not be used, copied, reproduced, distributed, transmitted, broadcast, displayed, sold, licensed, or otherwise exploited for any other purposes whatsoever without the prior written consent of the respective owners.
    </p>
    <p>
      Fapshort.com reserves all rights not expressly granted in and to the Website and the Content. You agree to not engage in the use, copying, or distribution of any of the Content other than expressly permitted herein, including any use, copying, and/or distribution of User Submissions of third parties obtained through the Website for any commercial purposes. If you download or print a copy of the Content for personal use, you must retain all copyright and other proprietary notices contained therein. You agree not to disable, circumvent, or otherwise interfere with security-related features of the Fapshort.com or features that prevent or restrict use or copying of any content or enforce limitations on use of the Fapshort.com website or the Content therein.
    </p>

    <h3>6. User Submissions</h3>
    <p>
      Fapshort.com permits the submission of photos/videos and other communications and the hosting, sharing, and publishing of such User Submissions. You understand that whether or not such user submissions are published and/or uploaded, Fapshort.com does not guarantee any confidentiality with respect to any submissions.
    </p>
    <p>
      Fapshort.com allows/permits you to link to materials on the website for personal, non-commercial purposes only. Fapshort.com reserves the right to discontinue any aspect of the Fapshort.com website at any time.
    </p>
    <p>
      You shall be solely responsible for any and all of your own user submissions and the consequences of posting, uploading, and publishing them. Furthermore, with User Submissions, you affirm, represent, and/or warrant that:
    </p>
    <ul>
      <li>You own or retain the necessary licenses, rights, consents, and permissions to use and authorize Fapshort.com to use all trademarks, copyrights, trade secrets, patents, or other proprietary rights in and to any and all User Submissions to enable inclusion and use of the User Submissions in the manner contemplated by the Website and these Terms of Service.</li>
      <li>You will not post, or allow anyone else to post, any material that depicts any person under the age of 18 years, and you have inspected and are maintaining written documentation sufficient to confirm that all subjects of your submissions are, in fact, over the age of 18 years.</li>
      <li>You have the written consent, release, and/or permission of each and every identifiable individual person in the User Submission to use the name or likeness of each and every such identifiable individual person to enable inclusion and use of the user submissions in the manner contemplated by the Website and these Terms of Service.</li>
    </ul>
    <p>
      By submitting the user submissions to Fapshort.com, you hereby grant Fapshort.com a worldwide, non-exclusive, royalty-free, sublicenseable and transferable license to use, reproduce, distribute, prepare derivative works of, display, and perform the User Submissions in connection with the Fapshort.com website and Fapshort.com’s (and its successor’s) business, including without limitation for promoting and redistributing part or all of the Fapshort.com website (and derivative works thereof) in any media formats and through any media channels.
    </p>
    <p>
      In submitting material (photos/video or other communication), you further agree that you shall not submit material that is copyrighted, protected by trade secret, or otherwise subject to third-party proprietary rights, including privacy and publicity rights, unless you are the owner of such rights or have permission from their rightful owner to post the material and to grant Fapshort.com all of the license rights granted herein.
    </p>

    <h3>7. Account Termination Policy</h3>
    <p>
      A User’s access to Fapshort.com will be terminated if, under appropriate conditions, the User is determined to infringe repeatedly. Fapshort.com reserves the right to decide whether Content or if a User’s Submission is appropriate and complies with these Terms and Conditions in regards to violations other than copyright infringement or privacy law, such as, but not limited to, hate crimes, pornography, obscene or defamatory material, or excessive length.
    </p>

    <h3>8. Policy</h3>
    <p>
      Fapshort.com abides by a ZERO TOLERANCE policy relating to any illegal content. Fapshort.com shall not condone under-age pornography and will cooperate with all governmental agencies that seek those who produce illegal pornography.
    </p>
    
    <h3>9. Fees</h3>
    <p>
      You agree to pay for all royalties, fees, and any other monies owing any person by reason of any Submissions posted by you to or through this website.
    </p>

    <h3>10. Warranties</h3>
    <p>
      You represent and warrant that all of the information provided by you to Fapshort.com to participate in the Fapshort.com website is accurate and current and you have all necessary rights, power, and authority to enter into this Agreement and to perform the acts required of you hereunder.
    </p>

    <h3>11. Warranty Disclaimer</h3>
    <p>
      YOU AGREE THAT YOUR USE OF THE Fapshort.COM WEBSITE SHALL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, Fapshort.COM, ITS OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE WEBSITE AND YOUR USE THEREOF.
    </p>

    <h3>12. Limitation of Liability</h3>
    <p>
      IN NO EVENT SHALL Fapshort.COM, ITS OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS, BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES WHATSOEVER RESULTING FROM ANY ERRORS, MISTAKES, OR INACCURACIES OF CONTENT.
    </p>

    <h3>13. Indemnity</h3>
    <p>
      You agree to defend, indemnify and hold harmless Fapshort.com, its parent corporation, officers, directors, employees, and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs, or debt, and expenses (including but not limited to attorney’s fees) arising from:
    </p>
    <ul>
      <li>Your use of and access to the Fapshort.com Website;</li>
      <li>Your violation of any term of these Terms of Service;</li>
      <li>Your violation of any third party right, including without limitation any copyright, property, or privacy right;</li>
      <li>Any claim that one of your User Submissions caused damage to a third party.</li>
    </ul>

    <h3>14. Assignment</h3>
    <p>
      The Terms and Conditions contained herein and any rights and licenses granted hereunder, may not be transferred or assigned by you, but may be assigned by Fapshort.com without restriction.
    </p>

    <h3>15. Affiliate Agreement</h3>
    <p>
      Please read the following Affiliate Agreement (the 'Agreement'). When you enroll to become an affiliate with Fapshort (the 'Program'), you agree to all of the terms and conditions of this Agreement.
    </p>

    <h3>Privacy Policy</h3>
    <p>
      This document details important information regarding the use and disclosure of user data collected on Fapshort.com. The security of your data is very important to Fapshort.com, and as such we take all appropriate steps to limit the risk that it may be lost, damaged, or misused.
    </p>

    <h3>Cookie Policy</h3>
    <p>
      This Cookie Policy explains how Fapshort ("Company", "we", "us", and "our") uses cookies and similar technologies to recognize you when you visit our websites at <a href="https://Fapshort.com" target="_blank">https://Fapshort.com</a>.
    </p>

    <h3>DMCA Notice</h3>
    <p>
      In accordance with the Digital Millennium Copyright Act of 1998, Fapshort.com will respond expeditiously to claims of copyright infringement that are reported to Fapshort.com’s designated copyright agent.
    </p>

    <h3>18 USC 2257</h3>
    <p>
      Fapshort.com is not a producer (primary or secondary) of any and all of the content found on the website (Fapshort.com). With respect to the records as per 18 USC 2257 for any and all content found on this site, please kindly direct your request to the site for which the content was produced.
    </p>
  </div>
</div>

</template>

<script>
import LoginForm from './LoginForm.vue'; // Import LoginForm component

export default {
  name: 'PartnerPage',
  components: {
    LoginForm,
  },
  data() {
    return {
 faqs: [
        {
          category: "",
          expanded: true, // Initially collapsed
          items: [
  {
    question: "What is FapShort?",
    expanded: true,
    answers: [
      "FapShort is a TikTok-like, mobile-friendly interface featuring a smart feed algorithm that adapts to your preferences and continuously provides content you'll enjoy.",
      "Despite being a newly launched platform, we already attract over 20 million visitors every month and are expanding rapidly. We have major plans ahead, so stay connected!",
      "For the optimal experience, download the official app on your mobile device."
    ]
  },
  {
    question: "Why was FapShort created?",
    expanded: true,
    answers: [
      "We were dissatisfied with the mobile user experience provided by most adult content sites, so we built FapShort.",
      "Our goal was to create a platform that:",
      "- Highlights content creators",
      "- Ensures safety without overwhelming users with pop-ups or ads",
      "- Is simple to use and learns from user preferences",
      "- Offers a high-quality interface specifically designed for mobile users"
    ]
  },
  {
    question: "How does the Feed operate?",
    expanded: true,
    answers: [
      "The feed is a flow of videos tailored to the user based on their interests, interactions with the platform, and the content they have previously viewed.",
      "The algorithm uses machine learning to identify which videos a user is most likely to interact with and ranks them higher in the feed.",
      "The feed is regularly updated with new material, ensuring users see fresh videos as they scroll.",
      "Additionally, users have the option to search for specific content or creators, follow popular hashtags, or explore videos by category."
    ]
  },
  {
    question: "What perks do I get as a Partner?",
    expanded: true,
    answers: [
      "As a Partner, you:",
      "- Can share your content",
      "- Receive traffic from users to your OnlyFans, Fansly, Linktree, Twitter, Instagram, and other sites",
      "- Gain significant visibility on FapShort",
      "- Work closely with FapShort team members",
      "- Enjoy numerous other benefits as we continuously enhance the Partner program!"
    ]
  },
  {
    question: "How can I join as a Partner?",
    expanded: true,
    answers: [
      "The steps to become a partner are outlined on our Partner Application page.",
      "Simply follow the instructions, and once our team approves your application, you become a Partner!"
    ]
  },
  {
    question: "What is the process for verifying my identity?",
    expanded: true,
    answers: [
      "At FapShort, we respect creators who prefer to remain anonymous in their content. However, FapShort is required to collect and retain written consent from all individuals featured in any uploaded content, and to verify the age and identity of every person in each piece of content.",
      "Thus, you need to confirm your identity and age during the partner application process. All information is securely stored and managed by our trusted verification partner, VerifyMy, who specialize in secure and compliant identity verification procedures."
    ]
  },
  {
    question: "How can I drive traffic to my links via FapShort?",
    expanded: true,
    answers: [
      "At FapShort, we recognize the importance of generating traffic for our Partners' websites and online profiles. Hence, we have made it straightforward for Partners to include links in their profiles and posts.",
      "By simply navigating to their profile settings, Partners can add links to their websites, social media pages, and other online platforms.",
      "These links are prominently displayed on their profile, and some (OnlyFans + a custom link) are even showcased on all of their posts, making it convenient for users to click through and visit their websites. This functionality provides an excellent opportunity for Partners to boost their visibility and drive more traffic to their online presence.",
      "We believe in fully empowering creators. Therefore, you are free to link to any other website you want, including those where you, as the Partner, earn from your content."
    ]
  },
  {
    question: "How do I submit my links?",
    expanded: true,
    answers: [
      "As a Partner, you can submit your links on the Edit Links Page."
    ]
  },
  {
    question: "How can I monitor the clicks I receive?",
    expanded: true,
    answers: [
      "For our Partners, it's essential to track the effectiveness of their links in driving traffic to their websites and online platforms. Therefore, all clicks are automatically tracked in our system.",
      "Partners have access to comprehensive click-through statistics and data, which can be viewed as charts and graphs. These insights show the number of clicks per link, the days with the most clicks, and even the geographical location of the users clicking on the links.",
      "These tools help Partners gain valuable insights into their links' performance, enabling them to make informed decisions on optimizing their online visibility."
    ]
  },
  {
    question: "Can I set up multiple accounts?",
    expanded: true,
    answers: [
      "Each person is permitted only one account.",
      "Due to some users creating multiple accounts for spam purposes, we had to enforce this policy.",
      "If we find someone has created multiple accounts, all accounts except the primary one will be removed."
    ]
  },
  {
    question: "Is it possible to create an account for someone else?",
    expanded: true,
    answers: [
      "No, you are only allowed to create an account for yourself.",
      "We will review accounts to ensure they mainly showcase the content of the person who set up the account."
    ]
  },
  {
    question: "Is FapShort private for Partners?",
    expanded: true,
    answers: [
      "USERS NEVER HAVE ACCESS TO ANY OF YOUR PERSONAL DATA.",
      "Users will only see your username and any information you have chosen to share publicly in your profile or uploaded content.",
      "Refer to our Privacy Policy for detailed information on how we collect, use, and safeguard your personal information."
    ]
  },
  {
    question: "How can I delete my account?",
    expanded: true,
    answers: [
      "You can remove your account by going into the settings and selecting the 'Delete Account' option.",
      "Please note that deleting your account is irreversible, and all your data will be permanently erased in line with our Privacy Policy. The process can take up to 24 hours to complete."
    ]
  },
  {
    question: "How do I reach Partner Support?",
    expanded: true,
    answers: [
      "Our support team is ready to assist our Partners with any inquiries or concerns they may have.",
      "Partners can reach the support team by emailing support@fapshort.com."
    ]
  }
  
]

        },
        // Add more FAQ categories here
      ],      showLoginForm: false, // State to control login form visibility
    };
  },
  methods: {
    handleBecomePartnerClick() {
      const token = localStorage.getItem('authToken');
      if (!token) {
        this.showLoginForm = true; // Show login form if not logged in
      } else {
        this.$router.push('/partners/application'); // Redirect to partner application page if logged in
      }
    },
    closeLoginForm() {
      this.showLoginForm = false; // Close the login form
    },
    handleLoginSuccess() {
      this.showLoginForm = false; // Hide login form after successful login
      this.$router.push('/partners/application'); // Redirect to partner application page
    },
    toggleFaq(index) {
      this.faqs[index].expanded = !this.faqs[index].expanded;
    },
  },
};
</script>
<style scoped>

.tos-section {
  background-color: #ffffff;
  padding: 30px;
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 800px;
}

.tos-content {
  max-height: 500px; /* Set max height to control scrolling */
  overflow-y: auto; /* Enable scrolling for overflow content */
  padding-right: 10px;
}

.tos-content p {
  text-align: left;
  font-size: 14px;
  color: #333;
  line-height: 1.6;
}

.faq-category {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #cbd5e0; /* Light border */
  padding: 1.25rem 1.25rem; /* Padding for button */
  cursor: pointer;
  background-color: transparent;
  color: #ffffff; /* Text color */
  transition: background-color 0.3s ease; /* Smooth background transition */
}

.faq-category:hover {
  background-color: #2d3748; /* Hover background */
}

.faq-content {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s ease;
}

.faq-content.expanded {
  max-height: 40000px; /* Large value to fully expand content */
}

.faq-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1.25rem; /* Padding for button */
  background-color: #2d3748; /* Button background */
  color: #ffffff; /* Text color */
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth background transition */
}

.faq-item:hover {
  background-color: #3b4a63; /* Hover background */
}

.faq-answer {
  overflow: hidden;
  max-height: 0;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  transition: max-height 0.5s ease;
}

.faq-answer.expanded {
  max-height: 1000px; /* Large value to fully expand content */
}

.text-bright {
  padding: 1rem; /* Add padding around text content */
  background-color: #2d3748; /* Background color for the text area */
}

.text-accent-50 {
  color: #ffffff; /* Light text color */
}

.text-md {
  font-size: 1rem; /* Standard text size */
}
.video-container {
  width: 100%; /* Make sure the container takes the full width */
  overflow: hidden; /* Hide any overflow content */
}

.video-container video {
  width: 100%; /* Make the video take up the full width */
  height: auto; /* Maintain aspect ratio */
  margin: 0; /* Remove any margin */
  padding: 0; /* Remove any padding */
  display: block; /* Ensure the video is displayed as a block element */
}

.text-base.mb-4.last\:mb-0.text-center {
  display: flex; /* Use flexbox to align */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.become-partner-button {
  background-color: #f53f85; /* Pink background */
  color: white; /* White text color */
  font-weight: bold; /* Bold text */
  padding: 15px 20px; /* Padding for the button */
  width: 200%; /* Make the button 200% width */
  max-width: 1300px; /* Increase maximum width to make the button 3x longer */
  border: none; /* Remove default button border */
  border-radius: 30px; /* Rounded corners */
  cursor: pointer; /* Cursor changes to pointer on hover */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
  
  position: relative; /* Set relative positioning */
  left: 50%; /* Move the button to the center horizontally */
  transform: translateX(-50%); /* Center the button by moving it left 50% of its own width */
  display: block; /* Make sure the button is displayed as a block */
  margin-top: 20px; /* Add some margin on top for spacing */
}

.become-partner-button:hover {
  background-color: #e0427f; /* Darker pink on hover */
}


.creator-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none; /* Remove underline */
  color: inherit;
  margin: 0 10px; /* Add some margin between each creator link */
}

.image-container {
  width: 6rem; /* Adjust size as needed */
  height: 6rem; /* Adjust size as needed */
  border-radius: 50%; /* Ensure circular images */
  overflow: hidden; /* Hide overflow to maintain circular shape */
}

.creator-link img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Cover to maintain aspect ratio */
}

.flex {
  display: flex; /* Flexbox layout for the container */
  flex-direction: row; /* Ensure horizontal arrangement */
}




.partner-page {
  background-color: #1a1a1a; /* Dark background */
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Make sure the entire screen height is covered */
  padding: 20px;
}
.text-container {
  text-align: center; /* Center text */
  background-color: #ffffff; /* White background for text box */
  border-radius: 15px; /* Rounded corners */
  padding: 30px; /* Increase padding inside the box */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Shadow for depth */
  display: flex; /* Add Flexbox */
  flex-direction: column; /* Ensure items stack vertically */
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
}

.creator-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: inherit;
  margin: 0 10px; /* Add some margin between each creator link */
}

.text-accent {
  color: #f53f85; /* Accent */
  font-size: 1.5rem; /* Increase font size for accent text */
}

.bg-dark {
  background-color: #1a1a1a; /* Dark background for the main page */
}
</style>
