import Vuex from 'vuex';
import axios from 'axios';

// Vuex store
export default new Vuex.Store({
    state: {
        videos: [],
        currentPage: 0
    },
    mutations: {
        appendVideos(state, videos) {
            state.videos.push(...videos); // Append new videos to the existing array
        },
        setVideos(state, videos) {
            // Append new videos to the existing list
            state.videos = [...state.videos, ...videos];
        },
        incrementPage(state) {
            state.currentPage++;
        }
    },
    actions: {
        fetchVideos({ commit, state }) {
            const url = `https://www.fapshort.com/videos?page=${state.currentPage + 1}&limit=10`;
            axios.get(url, {
                headers: {
                    'Accept': 'application/json'
                }
            })
                .then(response => {
                    commit('appendVideos', response.data);
                    commit('incrementPage');
                })
                .catch(error => {
                    console.error("Error fetching videos:", error);
                });
        }

    }
});




