<template>
  <div v-if="visible" class="toast" :class="[type]">
    {{ message }}
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'success', // Default to success type
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    show() {
      this.visible = true;
      setTimeout(() => {
        this.visible = false;
      }, 3000); // Show for 3 seconds
    },
  },
  watch: {
    message() {
      if (this.message) {
        this.show(); // Show the toast when the message changes
      }
    },
    type() {
      if (this.message) {
        this.show(); // Re-show the toast when the type changes and there's a message
      }
    },
  },
};
</script>

<style scoped>
.toast {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  transition: opacity 0.3s;
}

.toast.success {
  background-color: #4caf50; /* Green for success */
}

.toast.error {
  background-color: #f44336; /* Red for errors */
}
</style>
