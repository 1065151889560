import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // Import the router correctly
import store from './store';
import Vue3TouchEvents from 'vue3-touch-events';
import VueDirectiveTouch from 'vue-directive-touch';
import VueGtag from 'vue-gtag-next';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';

const app = createApp(App);

// Add FontAwesome icons to the library
library.add(fas);
app.component('font-awesome-icon', FontAwesomeIcon);

// Integrate Vue Router with your Vue application
app.use(router);

// Integrate Vuex Store
app.use(store);

// Use the vue3-touch-events and VueDirectiveTouch plugins
app.use(Vue3TouchEvents);
app.use(VueDirectiveTouch);

// Use Google Analytics Plugin (VueGtag) and configure it to work with Vue Router
app.use(
  VueGtag,
  {
    property: {
      id: 'G-NWM94ZSWPJ', // Replace with your GA tracking ID
    },
  },
  router // Ensure router is passed correctly to VueGtag
);

// Set up a global afterEach navigation guard to track page views
router.afterEach((to) => {
  if (app.config.globalProperties.$gtag) {
    // Ensure gtag is available before using it
    app.config.globalProperties.$gtag.pageview({
      page_path: to.fullPath, // Ensure correct path is tracked
      page_title: to.name, // Optionally, track page title
    });
  }
});

// Add a custom directive for intersection observer
app.directive('intersect', {
  mounted(el, binding) {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (typeof binding.value === 'function') {
            binding.value(entry); // Call the method provided to the directive
          } else {
            console.warn('v-intersect expects a function as a value');
          }
        }
      });
    }, options);

    el._observer = observer; // Store the observer instance on the element
    observer.observe(el);
  },
  unmounted(el) {
    if (el._observer) {
      el._observer.disconnect(); // Disconnect the observer when the element is removed
      delete el._observer;
    }
  },
});

// Mount the app to the DOM
app.mount('#app');
