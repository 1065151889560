import { createRouter, createWebHistory } from 'vue-router';
import ShortsViewComponent from '@/components/ShortsViewComponent.vue';  // Ensure correct path
import VideoGridComponent from '@/components/VideoGridComponent.vue';  // Ensure correct path
import PartnerPage from '@/components/PartnerPage.vue';  // Ensure correct path


const routes = [
  {
    path: '/',  // Home route
    name: 'shorts-view',  // Name used in goToShorts()
    component: ShortsViewComponent,
  },
  {
    path: '/search',  // Path for video grid
    name: 'video-grid',  // Name used in goToSearch()
    component: VideoGridComponent,  // Component to show for the /search route
  },
  {
    path: '/download',  // Path for download content
    name: 'download-content',  // Name used in goToDownloadContent()
    component: PartnerPage,  // Replace with the correct component
  },
  {
    path: '/bookmark',  // Path for bookmark page
    name: 'bookmark-page',  // Name used in goToBookmarkPage()
    component: ShortsViewComponent,  // Replace with the correct component
  },
  {
    path: '/profile',  // Path for profile page
    name: 'profile-page',  // Name used in goToProfile()
    component: ShortsViewComponent,  // Replace with the correct component
  },
  // Add any other routes you might have
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
