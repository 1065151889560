<template>
  <!-- <HeaderComponent @toggle-shorts="toggleShortsView" @search="searchVideos" /> -->
  <div class="content">
        <keep-alive>

    <div class="main-content">
      <!-- <VideoGridComponent
      v-if="activeComponent === 'videoGrid'"
      :shortVideos="videos"
      :longVideos="videos"
      :videos="videos"
      @select-video="playShort"
      @load-more-videos="loadMoreVideos"
      ref="videoGrid"
    /> -->
            <div v-if="loading" class="loading-spinner"></div>


<ShortsVideoGridComponent 
  v-if="activeComponent === 'videoGrid'"
  :videos="videos" 
  :premium="true"
  @close-shorts="handleCloseShorts"
  @load-more="loadMoreVideos" 
/>

<ShortsViewComponent
  v-if="activeComponent === 'shortsView'"
  :videos="videos"
  :shortsActive="shortsActive"
  :videoId="currentVideoIndex.toString()"
  :isLoggedIn="isLoggedIn"
  @exit-shorts="switchToShortsView"
  @load-more-videos="loadMoreVideos"
  @login-success="handleLoginSuccess"
  @update-login-status="updateLoginStatus"
   @submit-form="handleAuthForm" 
/>
      <PartnerPage v-if="activeComponent === 'partnerPage'" />
         <!-- Other components and structure -->
<ProfilePage 
  v-if="activeComponent === 'profilePage' && isLoggedIn" 
  :userVideos="userVideos" 
  @fetch-user-videos="fetchUserVideos" 
  @logout="logout"
/> <LoginForm
  v-if="activeComponent === 'profilePage' && !isLoggedIn"
  @close="activeComponent = ''"
  @submit-form="handleAuthForm"
  @login-success="handleLoginSuccess"
/>

    </div>
    </keep-alive>
      <ToastNotification ref="toast" :message="toastMessage" />

  </div>
  <!-- <footer class="footer">
    © 2024 Your Company Name. All Rights Reserved.

    <div class="footer-links">
      <br />
      <a href="https://example.com" class="nav-button">Contact Us</a>
      <a href="https://another-example.com" class="nav-button"
        >More Resources</a
      >
      <button @click="openPartnersModal" class="nav-button">
        Partner Program
      </button>
    </div>
  </footer> -->
  <nav class="bottom-navbar">
    <button class="navbar-button" @click="switchToShortsView">
      <font-awesome-icon :icon="['fas', 'home']" />
    </button>
    <button class="navbar-button" @click="switchToVideoGrid">
      <font-awesome-icon :icon="['fas', 'search']" />
    </button>
    <button class="navbar-button" @click="switchToPartnerPage">
      <font-awesome-icon :icon="['fas', 'award']" />
    </button>
    <!-- <button class="navbar-button" @click="goToBookmarkPage">
      <font-awesome-icon :icon="['fas', 'bookmark']" />
    </button> -->
    <button class="navbar-button" @click="goToProfile">
      <font-awesome-icon :icon="['fas', 'user']" />
    </button>
  </nav>

</template>

<script>
import axios from "axios";
// import HeaderComponent from "./components/HeaderComponent.vue";
// import VideoGridComponent from "./components/VideoGridComponent.vue";
import ShortsViewComponent from "./components/ShortsViewComponent.vue";
import PartnerPage from "./components/PartnerPage.vue";
import ProfilePage from "./components/ProfilePage.vue"; 
import ShortsVideoGridComponent from './components/ShortsVideoGridComponent.vue';
import LoginForm from './components/LoginForm.vue';
import ToastNotification from './components/ToastNotification.vue';


export default {
  name: "App",
  components: {
    // HeaderComponent,
    // VideoGridComponent,
     ToastNotification,
    LoginForm,
    ShortsViewComponent,
    PartnerPage,
    ProfilePage,
    ShortsVideoGridComponent,

  },
  data() {
    return {
      activeComponent: 'shortsView',
      videos: [],
      loadingVideos: [],
      shortsActive: true,
      currentVideoIndex: 0,
      shortVideos: [],
      longVideos: [],
      fetchedVideoIds: [],
      excludedIds: [],
      isLoggedIn: false,
      sessionCheckInterval: null,
       showShorts: false,
      filteredVideos: [],
      userVideos: [],
      toastMessage: '',
      loading: false,
    };
  },
watch: {
  isLoggedIn(newValue) {
    if (!newValue) {
      // Check if the state was inadvertently reset
      const storedStatus = localStorage.getItem('isLoggedIn') === 'true';
      if (storedStatus) {
        this.isLoggedIn = true; // Re-sync the state if it was wrongly altered
      }
    }
  },
},

  created() {
    this.checkSessionExpiry();
    // this.checkLoginStatus();
      this.restoreLoginStatus(); // Check stored login status on creation
    this.fetchVideos();

   },
   beforeUnmount() {
  clearInterval(this.sessionCheckInterval); // Clear interval to prevent memory leak
},
  methods: {
    async handleAuthForm(formData) {
    console.log('attempting to login');
    console.log(formData);
    this.isSubmitting = true;
    const url = formData.isSignUp ? 'https://www.fapshort.com/users' : 'https://www.fapshort.com/login';
    const formPayload = new FormData();
    formPayload.append('user[email]', formData.email);
    formPayload.append('user[password]', formData.password);

    // Add signup-specific fields
    if (formData.isSignUp) {
      formPayload.append('user[username]', formData.username);
      formPayload.append('user[description]', formData.description);
      formPayload.append('user[onlyfans_link]', formData.onlyfansLink);
      formPayload.append('user[other_links]', formData.otherLinks);
      if (formData.profileImage) {
        formPayload.append('user[profile_image]', formData.profileImage);
      }
      formPayload.append('user[password_confirmation]', formData.passwordConfirmation);
    }

    try {
      const response = await axios.post(url, formPayload, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      });
      console.log(response.data);

      if (formData.isSignUp) {
        this.$emit('login-success', {
          token: response.data.token,
          user: response.data.user,
        });
      } else {
        this.handleLoginSuccess({ token: response.data.token, user: response.data.user });
      }
    }  catch (error) {
      console.error('Error:', error);
      // Set the error message and show the toast in red
      this.toastMessage = 'Failed to login. Please check your credentials.';
      this.toastType = 'error'; // Set type to error to show red toast
      this.$refs.toast.show();
    } finally {
      this.isSubmitting = false;
    }
  },
    openShortsPage(videos) {
      this.filteredVideos = videos; // Assign the list of videos obtained from the search
      this.showShorts = true;
    },
    handleCloseShorts() {
      this.showShorts = false;
    },
    switchToShortsView() {
    this.activeComponent = 'shortsView';
  },
  switchToVideoGrid() {
    this.activeComponent = 'videoGrid';
  },
  switchToPartnerPage() {
    this.activeComponent = 'partnerPage';
  },
    goToBookmarkPage() {
    this.$router.push({ name: 'bookmark-page' });
  },
  goToProfile() {
    this.activeComponent = 'profilePage'; // Change to switch to ProfilePage
  },
     goToShorts() {
      this.$router.push({ name: 'shorts-view' }); // Navigate to ShortsViewComponent
    },
    goToSearch() {
      this.$router.push({ name: 'video-grid' }); // Navigate to VideoGridComponent
    },
    goToDownloadContent() {
      this.$router.push({ name: 'download-content' }); // Navigate to DownloadContentPage
    },
   
      handleLoginSuccess({ token, user }) {
      this.isLoggedIn = true;
      localStorage.setItem('isLoggedIn', 'true');
      localStorage.setItem('authToken', token);
      localStorage.setItem('userId', user.id);
      localStorage.setItem('premium', user.premium ? 'true' : 'false');
      localStorage.setItem('loginTimestamp', Date.now());
      this.checkSessionExpiry();
      this.fetchUserVideos(user.id);
       this.toastMessage = 'Success! You have logged in.';
      this.$refs.toast.show();
    },
  restoreLoginStatus() {
      const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
      const authToken = localStorage.getItem('authToken');
      const loginTimestamp = localStorage.getItem('loginTimestamp');
      const sessionDuration = 10 * 60 * 1000; // 40 minutes in milliseconds

      if (isLoggedIn && authToken && loginTimestamp) {
        if (Date.now() - parseInt(loginTimestamp) <= sessionDuration) {
          this.isLoggedIn = true;
          localStorage.setItem('loginTimestamp', Date.now());
          this.checkSessionExpiry();
        } else {
          this.logout();
        }
      } else {
        this.isLoggedIn = false;
      }
    },
  updateLoginStatus(status) {
    this.isLoggedIn = status;
  },
    checkScreenSize() {
      if (window.innerWidth <= 768) {
        // Adjust the width threshold as needed
        this.shortsActive = true;
      }
    },
    async checkLoginStatus() {
      
    const token = localStorage.getItem('authToken');
    const loginTimestamp = localStorage.getItem('loginTimestamp');
    const sessionDuration = 10 * 60 * 1000; // 30 minutes in milliseconds

    if (token && loginTimestamp && Date.now() - parseInt(loginTimestamp) <= sessionDuration) {
      try {
        // Make an API call to verify the token
        const response = await fetch('https://www.fapshort.com/api/check_token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.ok) {
          const data = await response.json();
          this.isLoggedIn = data.isValid; // Assuming the response contains an isValid field
        } else {
          this.isLoggedIn = false;
          this.logout();
        }
      } catch (error) {
        console.error('Error verifying token:', error);
        this.isLoggedIn = false;
        this.logout();
      }
    } else {
      this.isLoggedIn = false;
      this.logout();
    }
  },
 async fetchUserVideos(userId) {
  if (this.loading) return; // Prevent multiple fetches if already loading
  this.loading = true;
  try {
    this.currentPage++;
    if (isNaN(this.currentPage) || this.currentPage <= 0) {
      this.currentPage = 1;
    }
    this.excludedIds.push(this.userVideos.map((video) => video.id).join(',')); // Track excluded IDs

    // Fetch user videos with pagination and excluded IDs
    const response = await axios.get(
      `https://www.fapshort.com/videos?user_id=${userId}&page=${this.currentPage}&limit=10&excluded_ids=${this.excludedIds}`,
      {
        headers: {
          Accept: "application/json",
        },
      }
    );

    const newVideos = response.data;
    console.log('Fetched User Videos:', newVideos);

    // Check if each video is fully loaded before adding to the list
    const loadedVideos = await Promise.all(newVideos.map(this.checkVideoLoadStatus));

    // Filter out any videos that failed to load
    const fullyLoadedVideos = loadedVideos.filter((video) => video !== null);

    // Add fully loaded videos to the user video list
    this.userVideos.push(...fullyLoadedVideos);
    this.fetchedVideoIds.push(...fullyLoadedVideos.map((video) => video.id));
  } catch (error) {
    console.error('Error fetching user videos:', error);
  } finally {
    this.loading = false;
  }
},

checkSessionExpiry() {
      const loginTimestamp = localStorage.getItem('loginTimestamp');
      const sessionDuration = 10 * 60 * 1000; // 10 minutes
      if (loginTimestamp && Date.now() - parseInt(loginTimestamp) > sessionDuration) {
        this.logout();
      } else if (!this.sessionCheckInterval) {
        this.sessionCheckInterval = setInterval(this.checkSessionExpiry, 60 * 1000);
      }
    },
  async updateUserProfile(payload) {
      try {
        console.log('Payload being sent:', payload);  // Log the payload
        const userId = localStorage.getItem('userId'); // Get userId from local storage

        const response = await axios.patch(
          `https://www.fapshort.com/users/${userId}`, // Updated endpoint
          { user: payload.user },  // Correct structure
          {
            headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
          }
        );

        console.log('Response from server:', response.data);  // Log the response
        alert('Profile updated successfully!');
      } catch (error) {
        console.error('Error updating profile:', error);
        if (error.response && error.response.data) {
          console.log('Validation Errors:', error.response.data.errors);  // Log validation errors
          alert(`Failed to update profile. Errors: ${JSON.stringify(error.response.data.errors)}`);
        } else {
          alert('Failed to update profile. Please try again.');
        }
      }
    },
   logout() {
    // Remove user-specific data from local storage
    localStorage.removeItem('authToken');
    localStorage.removeItem('loginTimestamp');
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('premium');
    localStorage.removeItem('userId'); // Add this line to remove the userId
    this.isLoggedIn = false;
    clearInterval(this.sessionCheckInterval);
    this.sessionCheckInterval = null;
    this.$router.push('/login'); // Redirect to login page
  },
    async searchVideos(query) {
      try {
        const response = await axios.get(
          `https://www.fapshort.com/videos?search=${query}`,
          {
            headers: {
              Accept: "application/json",
            },
          }
        );
        this.videos = response.data; // This will now contain only the search results
        console.log("Searched Videos:", this.videos);
        this.longVideos = this.videos;
      } catch (error) {
        console.error("There was an error searching the videos:", error);
      }
    },
    searchVideosByTag(tag) {
      axios
        .get(`https://www.fapshort.com/videos?tag=${tag}`, {
          headers: {
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.videos = response.data; // Update your videos list
          console.log("Searched Videos:", this.videos);

          this.longVideos = this.videos;
        })
        .catch((error) => {
          console.error("Error fetching videos by tag:", error);
        });
    },
    toggleShortsView() {
      this.shortsActive = !this.shortsActive;
      
      // Ensure currentVideoIndex is within the range
      if (this.videos.length > 0) {
        this.currentVideoIndex = Math.floor(Math.random() * this.videos.length);
      } else {
        this.currentVideoIndex = 0; // Default to the first index if videos are empty
      }

      this.restoreScrollPosition();
    },
    playShort(index) {
      this.saveScrollPosition();

      console.log("Attempting to navigate to video with ID:", index);
      if (index >= 0 && index < this.videos.length) {
        this.currentVideoIndex = index; // Only set if within valid range
        this.shortsActive = true; // Switch to Shorts view
      } else {
        console.warn("Invalid video index provided:", index);
      }
    },

     async loadMoreVideos() {
    if (this.loading) return;
    this.loading = true;
    try {
      this.currentPage++;
      if (isNaN(this.currentPage) || this.currentPage <= 0) {
        this.currentPage = 1;
      }
      this.excludedIds.push(this.videos.map((video) => video.id).join(','));
      console.log(this.excludedIds);

      // Fetch new videos
      const response = await axios.get(
        `https://www.fapshort.com/videos?page=${this.currentPage}&limit=10&excluded_ids=${this.excludedIds}`,
        {
          headers: {
            Accept: "application/json",
          },
        }
      );
      console.log(response.data);
      const newVideos = response.data;

      // Check if each video is fully loaded before adding to the list
      const loadedVideos = await Promise.all(newVideos.map(this.checkVideoLoadStatus));

      // Filter out any videos that failed to load
      const fullyLoadedVideos = loadedVideos.filter((video) => video !== null);

      // Add fully loaded videos to the main video list
      this.videos.push(...fullyLoadedVideos);
      this.shortVideos.push(...fullyLoadedVideos.filter((video) => video.is_short));
      this.longVideos.push(...fullyLoadedVideos.filter((video) => !video.is_short));
      this.fetchedVideoIds.push(...fullyLoadedVideos.map((video) => video.id));
    } catch (error) {
      console.error("There was an error fetching more videos:", error);
    } finally {
      this.loading = false;
    }
  },

  // Function to check if a video is fully loaded
  async checkVideoLoadStatus(video) {
    return new Promise((resolve) => {
      const videoElement = document.createElement('video');
      videoElement.src = video.url;
      videoElement.preload = 'auto';

      // Event listener to check if video has loaded metadata
      videoElement.addEventListener('loadedmetadata', () => {
        console.log(`Video ${video.id} loaded successfully.`);
        resolve(video); // Resolve with the video object when fully loaded
      });

      // Error event listener in case the video fails to load
      videoElement.addEventListener('error', () => {
        console.error(`Failed to load video ${video.id}.`);
        resolve(null); // Resolve with null if the video fails to load
      });

      // Optional: Event listener for canplaythrough to ensure the video can play smoothly
      videoElement.addEventListener('canplaythrough', () => {
        console.log(`Video ${video.id} is fully ready to play.`);
        resolve(video);
      });
    });
  },
    // async loadMoreVideos() {
    //   if (this.loading) return;
    //   this.loading = true;
    //   try {
    //     this.currentPage++;
    //     if (isNaN(this.currentPage) || this.currentPage <= 0) {
    //       this.currentPage = 1;
    //     }
    //     const response = await axios.get(
    //       `https://www.fapshort.com/videos?page=${this.currentPage}&limit=40`,
    //       {
    //         headers: {
    //           Accept: "application/json",
    //         },
    //       }
    //     );
    //     const newVideos = response.data;
    //     this.videos.push(...newVideos);
    //     this.shortVideos.push(...newVideos.filter((video) => video.is_short));
    //     this.longVideos.push(...newVideos.filter((video) => !video.is_short));
    //   } catch (error) {
    //     console.error("There was an error fetching more videos:", error);
    //   } finally {
    //     this.loading = false;
    //   }
    // },
    saveScrollPosition() {
      const container = this.$refs.videoGrid.$refs.scrollContainer;
      this.scrollPosition = container.scrollLeft;
    },
    restoreScrollPosition() {
      this.$nextTick(() => {
        const container = this.$refs.videoGrid.$refs.scrollContainer;
        container.scrollLeft = this.scrollPosition;
      });
    },
async fetchVideos(retryCount = 3) {
  let attempts = 0;
  this.loading = true; // Show the spinner while loading

  while (attempts < retryCount) {
    try {
      const response = await axios.get("https://www.fapshort.com/videos", {
        headers: {
          Accept: "application/json",
        },
      });

      console.log("Response data:", response.data); // Log the response data

      // Temporary hold fetched videos to verify loading status
      this.loadingVideos = response.data || [];

      // Check if each video is fully loaded before adding to the main list
      const loadedVideos = await Promise.all(
        this.loadingVideos.map(this.checkVideoLoadStatus)
      );

      // Filter out any videos that failed to load
      const fullyLoadedVideos = loadedVideos.filter((video) => video !== null);

      // Add fully loaded videos to the main video list
      this.currentPage = 1;
      this.videos = fullyLoadedVideos;
      this.shortVideos = fullyLoadedVideos.filter((video) => video.is_short);
      this.longVideos = fullyLoadedVideos.filter((video) => !video.is_short);
      this.fetchedVideoIds.push(...fullyLoadedVideos.map((video) => video.id));

      // Clear temporary list after processing
      this.loadingVideos = [];
      return; // If successful, exit the function
    } catch (error) {
      attempts++;
      console.error(
        `There was an error fetching the videos (attempt ${attempts}/${retryCount}):`,
        error
      );
      if (attempts >= retryCount) {
        console.error("Max retry attempts reached. Unable to fetch videos.");
      }
    } finally {
      this.loading = false; // Hide the spinner once done
    }
  }
}


  },
  mounted() {
    // this.fetchVideos();const userId = localStorage.getItem('userId');
    const userId = localStorage.getItem('userId');
    console.log('locally stored user id:', userId)
    if (userId) {
      this.fetchUserVideos(userId);
    }
    this.checkScreenSize(); // Check screen size when component mounts
    this.fetchedVideoIds = this.videos.map((video) => video.id);
  },
};
</script>

<style>
.loading-spinner {
  position: fixed; /* Position the spinner fixed in the viewport */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust to center the spinner exactly */
  border: 8px solid #f3f3f3; /* Light grey border */
  border-top: 8px solid #ff0077; /* Blue border on the top */
  border-radius: 50%; /* Make it round */
  width: 60px; /* Width of the spinner */
  height: 60px; /* Height of the spinner */
  animation: spin 2s linear infinite; /* Spinning animation */
  z-index: 1000; /* Ensure it's on top of other elements */
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.bottom-navbar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #111; /* Dark background */
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  z-index: 1000; /* Ensure it's above other content */
}

.navbar-button {
  background: none;
  border: none;
  color: rgb(255, 255, 255);
  font-size: 24px; /* Icon size */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.navbar-button:hover {
  color: #999; /* Hover effect */
}

/* Add Font Awesome or similar icon library for icons */
.icon-home::before {
  content: "\f015"; /* FontAwesome home icon */
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.icon-search::before {
  content: "\f002"; /* FontAwesome search icon */
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.icon-download::before {
  content: "\f019"; /* FontAwesome download icon */
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.icon-bookmark::before {
  content: "\f02e"; /* FontAwesome bookmark icon */
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.icon-profile::before {
  content: "\f007"; /* FontAwesome user icon */
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

#app {
  background-color: #020202;
  min-height: 100vh;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}

.content {
  display: flex; /* Set the main container to flex to align children horizontally */
  flex-direction: column; /* Stack sidebar and content vertically on smaller screens */
}
.footer {
  background-color: #000; /* Footer background color */
  color: #fff; /* Footer text color */
  text-align: center; /* Centers the footer text */
  padding: 20px 0; /* Adds padding to the footer */
  font-size: 16px; /* Set the font size for the footer */
}

.footer-button {
  background: none;
  border: 2px solid #fff;
  color: #fff;
  padding: 8px 16px;
  margin: 2px;
  transition: background-color 0.3s;
  cursor: pointer;
}

@media (max-width: 767px) {
  .footer-button {
    padding: 10px 20px; /* Adjust padding for smaller screens */
    font-size: smaller; /* Adjust font size if necessary */
  }
}

@media (min-width: 768px) {
  .footer-button {
    padding: 8px 16px; /* Standard padding */
  }
}
.sidebar {
  width: 100%; /* Full width on smaller screens */
  order: 2; /* Sidebar goes below the content on small screens */
}

.main-content {
  order: 1; /* Content shows first on small screens */
  width: 100%; /* Ensure it takes full width on smaller devices */
}

@media (min-width: 768px) {
  .content {
    flex-direction: row; /* Side by side on larger screens */
  }

  .sidebar {
    width: 200px; /* Fixed width on larger screens */
    order: 1; /* Reset order */
  }

  .main-content {
    flex-grow: 1; /* Allow main content to fill available space */
    order: 2; /* Reset order */
  }
}

.nav-buttons-container {
  display: flex;
  justify-content: center;
  background-color: #333; /* Dark background for the button container */
  padding: 10px 0;
}

.nav-button {
  padding: 10px 20px;
  margin: 0 10px;
  background-color: #555; /* Slightly lighter grey for buttons */
  border: none;
  border-radius: 4px; /* Slightly rounded corners for buttons */
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.nav-button:hover {
  background-color: #777; /* Change color on hover */
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

/* Style for individual list items */
li {
  padding: 8px 16px; /* Adjust the padding as needed */
  margin-bottom: 5px; /* Space between list items */
  /* Add more styles as needed */
}

/* Style for links to make them more attractive */
li a {
  text-decoration: none; /* Remove underline from links */
  color: #333; /* Set your desired text color */
  display: block; /* Make the link fill the entire list item for easier clickability */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effects */
}

/* Hover effect for links */
li a:hover {
  background-color: #f5f5f5; /* Light grey background on hover */
  color: #000; /* Darker text on hover */
}
</style>

