<template>
  <div class="container">
    <!-- <button class="close-shorts-button" @click="closeShortsPage">&times;</button> -->

    <!-- Search Bar for Tags -->
    <div class="search-bar">
      <input
        type="text"
        v-model="searchQuery"
        placeholder="Search by tags (e.g., Anal, Blowjob)"
        @keyup.enter="searchByTags"
        class="search-input"
      />
      <button @click="searchByTags" class="search-button">Search</button>
    </div>
    
    <a href="https://t.ajrkm3.com/339978/8780/0?bo=2779,2778,2777,2776,2775&file_id=602574&po=6533&aff_sub5=SF_006OG000004lmDN&aff_sub4=AT_0002"><img src="https://www.imglnkx.com/8780/PMKT-1132_DESIGN-17536_AlyxStar_Jerkmate_300100.gif" width="300" height="100" border="0" /></a>
<!-- <a href="https://t.ajrkm.link/339978/2676/0?bo=2779,2778,2777,2776,2775&file_id=122745&po=6533&aff_sub5=SF_006OG000004lmDN&aff_sub4=AT_0002"><img src="https://www.imglnkx.com/2676/001753A_LIJA_18_ALL_EN_71_L.gif" width="300" height="250" border="0" /></a> -->
    <!-- Short-form content grid -->
    <h2 class="section-title" v-if="premium">Shorts</h2>
<section class="shorts-grid" v-if="premium">
  <div v-for="(item, index) in displayedVideos" :key="index" class="short-item">
    <div v-if="item.type === 'video'" class="short-wrapper">
      <video width="250" @click="selectVideo(item.data)">
        <source :src="item.data.url" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <button class="overlay-button" @click="selectVideo(item.data)"></button>
    </div>
    <div v-if="item.type === 'banner'" class="banner-wrapper">
      <a :href="item.data.href" target="_blank" v-if="item.data.href">
        <img :src="item.data.src" :width="item.data.width" :height="item.data.height" border="0" v-if="item.data.src" />
      </a>
    </div>
  </div>
</section>


    <!-- Load More Button -->
    <button
      class="load-more-button"
      @click="loadMoreVideos"
      v-if="premium && !loadingMore"
    >
      Load More
    </button>

    <!-- Video player -->
    <VideoPlayer
      v-if="selectedVideo"
      :video="selectedVideo"
      @close="closeVideoPlayer"
    />
  </div>
</template>

<script>
import axios from "axios"; // Ensure axios is imported
import VideoPlayer from "./VideoPlayer.vue";

export default {
  name: "ShortsVideoGridComponent",
  props: {
    videos: {
      type: Array,
      default: () => [],
    },
    premium: Boolean,
  },
   computed: {
    displayedVideos() {
      const videosWithBanners = [];
      this.localVideos.forEach((video, index) => {
        videosWithBanners.push({ type: 'video', data: video });
        if ((index + 1) % 10 === 0 && this.banners.length) {
          const bannerIndex = Math.floor(index / 10);
          if (this.banners[bannerIndex]) {
            videosWithBanners.push({ type: 'banner', data: this.banners[bannerIndex] });
          }
        }
      });
      return videosWithBanners;
    },
  },


  data() {
    return {
      selectedVideo: null,
      localVideos: [], // Local copy of the videos
      loadingMore: false, // Loading state
      currentPage: 1, // Track the current page for pagination
      excludedIds: [], // Track excluded video IDs for loading more videos
      searchQuery: "", // Search query for tags
         banners: [
          {
          href: 'https://t.ajrkm3.com/339978/8780/0?bo=2779,2778,2777,2776,2775&file_id=598296&po=6533&aff_sub5=SF_006OG000004lmDN&aff_sub4=AT_0002',
          src: 'https://www.imglnkx.com/8780/PMKT-1157_DESIGN-16618_BannersWebinar_SexiVegasXX_300100.gif',
          width: 300,
          height: 250,
        },
        {
          href: 'https://t.ajrkm.link/339978/2676/0?bo=2779,2778,2777,2776,2775&file_id=122745&po=6533&aff_sub5=SF_006OG000004lmDN&aff_sub4=AT_0002',
          src: 'https://www.imglnkx.com/8780/HawkTuah_Banners_GinaValentina_v1_300100.gif',
          width: 300,
          height: 250,
        },
        {
          href: 'https://t.ajrkm.link/339978/2676/0?bo=2779,2778,2777,2776,2775&file_id=122745&po=6533&aff_sub5=SF_006OG000004lmDN&aff_sub4=AT_0002',
          src: 'https://www.imglnkx.com/2676/001753A_LIJA_18_ALL_EN_71_L.gif',
          width: 300,
          height: 250,
        },        
        {
          href: 'https://t.ajrkm3.com/339978/8780/0?bo=2779,2778,2777,2776,2775&file_id=610814&po=6533&aff_sub5=SF_006OG000004lmDN&aff_sub4=AT_0002',
          src: 'https://www.imglnkx.com/8780/JM-379_DESIGN-20876_300100_1.gif',
          width: 300,
          height: 250,
        },
        {
          href: 'https://t.ajrkm.link/339978/2676/0?bo=2779,2778,2777,2776,2775&file_id=122751&po=6533&aff_sub5=SF_006OG000004lmDN&aff_sub4=AT_0002',
          src: 'https://www.imglnkx.com/2676/001754A_LIJA_13_ALL_EN_71_L.gif',
          width: 300,
          height: 250,
        },
        {
          href: 'https://t.ajrkm.link/339978/2676/0?bo=2779,2778,2777,2776,2775&file_id=122779&po=6533&aff_sub5=SF_006OG000004lmDN&aff_sub4=AT_0002',
          src: 'https://www.imglnkx.com/2676/001760B_LIJA_18_ALL_EN_71_L.jpg',
          width: 300,
          height: 250,
        },{
          href: 'https://t.ajrkm3.com/339978/8780/0?bo=2779,2778,2777,2776,2775&file_id=598462&po=6533&aff_sub5=SF_006OG000004lmDN&aff_sub4=AT_0002',
          src: 'https://www.imglnkx.com/8780/PMKT-1157_DESIGN-16618_BannersWebinar_AmyPose_300100.gif',
          width: 300,
          height: 250,
        },
        {
          href: 'https://t.ajrkm3.com/339978/8780/0?bo=2779,2778,2777,2776,2775&file_id=602574&po=6533&aff_sub5=SF_006OG000004lmDN&aff_sub4=AT_0002',
          src: 'https://www.imglnkx.com/8780/PMKT-1132_DESIGN-17536_AlyxStar_Jerkmate_300100.gif',
          width: 300,
          height: 250,
        },
          {
          href: 'https://t.ajrkm3.com/339978/8780/0?bo=2779,2778,2777,2776,2775&file_id=598296&po=6533&aff_sub5=SF_006OG000004lmDN&aff_sub4=AT_0002',
          src: 'https://www.imglnkx.com/8780/PMKT-1157_DESIGN-16618_BannersWebinar_SexiVegasXX_300100.gif',
          width: 300,
          height: 250,
        },
        {
          href: 'https://t.ajrkm.link/339978/2676/0?bo=2779,2778,2777,2776,2775&file_id=122745&po=6533&aff_sub5=SF_006OG000004lmDN&aff_sub4=AT_0002',
          src: 'https://www.imglnkx.com/8780/MOH_Jerkmate_300x100_04.gif',
          width: 300,
          height: 250,
        },
        
        {
          href: 'https://t.ajrkm3.com/339978/8780/0?bo=2779,2778,2777,2776,2775&file_id=610814&po=6533&aff_sub5=SF_006OG000004lmDN&aff_sub4=AT_0002',
          src: 'https://www.imglnkx.com/8780/JM-379_DESIGN-20876_300100_1.gif',
          width: 300,
          height: 250,
        },
        {
          href: 'https://t.ajrkm.link/339978/2676/0?bo=2779,2778,2777,2776,2775&file_id=122751&po=6533&aff_sub5=SF_006OG000004lmDN&aff_sub4=AT_0002',
          src: 'https://www.imglnkx.com/8780/HawkTuah_Banners_CartoonCowgirl_300100.gif',
          width: 300,
          height: 250,
        },
        
        {
          href: 'https://t.ajrkm.link/339978/2676/0?bo=2779,2778,2777,2776,2775&file_id=122779&po=6533&aff_sub5=SF_006OG000004lmDN&aff_sub4=AT_0002',
          src: 'https://www.imglnkx.com/8780/000705F_JRKM_18_ALL_EN_55_L.gif',
          width: 300,
          height: 250,
        },{
          href: 'https://t.ajrkm3.com/339978/8780/0?bo=2779,2778,2777,2776,2775&file_id=598462&po=6533&aff_sub5=SF_006OG000004lmDN&aff_sub4=AT_0002',
          src: 'https://www.imglnkx.com/8780/SM-379_DESIGN-19517_SplitCartoon_KylieRocket__300100.jpg',
          width: 300,
          height: 250,
        },
        {
          href: 'https://t.ajrkm3.com/339978/8780/0?bo=2779,2778,2777,2776,2775&file_id=602574&po=6533&aff_sub5=SF_006OG000004lmDN&aff_sub4=AT_0002',
          src: 'https://www.imglnkx.com/8780/PMKT-1132_DESIGN-17536_AlyxStar_Jerkmate_300100.gif',
          width: 300,
          height: 250,
        },
 ],
    };
  },
  components: {
    VideoPlayer,
  },
  methods: {
    // Method to load more videos
    async loadMoreVideos() {
      if (this.loadingMore) return;
      this.loadingMore = true;
      try {
        this.currentPage++;
        if (isNaN(this.currentPage) || this.currentPage <= 0) {
          this.currentPage = 1;
        }
        const excludedIds = this.localVideos.map((video) => video.id).join(",");
        console.log("Excluded IDs:", excludedIds);

        const response = await axios.get(
          `https://www.fapshort.com/videos?page=${this.currentPage}&limit=40&excluded_ids=${excludedIds}`,
          {
            headers: {
              Accept: "application/json",
            },
          }
        );

        console.log("New videos fetched:", response.data);
        const newVideos = response.data;

        // Ensure new videos are added to localVideos
        this.localVideos.push(...newVideos);
      } catch (error) {
        console.error("There was an error fetching more videos:", error);
      } finally {
        this.loadingMore = false;
      }
    },

    // Method to search videos by tags
    async searchByTags() {
      if (!this.searchQuery.trim()) {
        // If the search query is empty, reset to all videos
        this.localVideos = [...this.videos];
        return;
      }

      try {
        // Fetch videos that match the search tags
        const response = await axios.get(`https://www.fapshort.com/videos`, {
          params: {
            tag: this.searchQuery, // Pass tags as a parameter to the backend
          },
          headers: {
            Accept: "application/json",
          },
        });

        // Update the filtered videos
        this.localVideos = response.data;
        console.log("Filtered videos:", this.localVideos);
      } catch (error) {
        console.error("Error fetching videos by tags:", error);
        alert("Failed to fetch videos by tags. Please try again.");
      }
    },

    selectVideo(video) {
      console.log(video.url);
      this.selectedVideo = { ...video, url: video.url };
    },
    closeVideoPlayer() {
      this.selectedVideo = null;
    },
    closeShortsPage() {
      this.$emit("close-shorts");
    },
  },
  mounted() {
    this.localVideos = [...this.videos];
  },
  watch: {
    videos(newVideos) {
      this.localVideos = [...newVideos];
    },
  },
};
</script>

<style scoped>
.banner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0; /* Adjust spacing as needed */
}

.banner-wrapper img {
  max-width: 100%;
  height: auto;
  border: 0;
}

.container {
   background-color: rgba(0, 0, 0, 1);
  margin: 0; /* Remove any margin */
  padding: 0; /* Remove any padding */
  width: 100%; /* Ensure it takes full width */
  height: 100%; /* Optional, ensure it takes full height */
}

.close-shorts-button {
  position: fixed;
  top: 20px;
  right: 20px;
  background: transparent;
  border: none;
  color: white;
  font-size: 2em;
  cursor: pointer;
  z-index: 10;
}

.search-bar {
  display: flex;
  gap: 10px;
  margin: 20px auto; /* Center the search bar horizontally */
  padding-top: 20px; /* Add top padding to move the search bar down */
  max-width: 90%; /* Set the maximum width as needed */
  justify-content: center; /* Center items horizontally */
}

.search-input {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.search-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #f53f85;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}


.search-button:hover {
  background-color: #f53f85;
}

.section-title {
  text-align: left;
  padding: 0 1rem;
}

.shorts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 10px;
  padding: 1px;
  max-width: 100%;
}
.short-item {
  position: relative;
  width: 100%;
  cursor: pointer;
}
.short-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
}
video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.overlay-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.01);
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.overlay-button:hover {
  background: rgba(0, 0, 0, 0.1);
}

.load-more-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #444;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.load-more-button:hover {
  background-color: #666;
}
</style>
