<template>
  <div class="video-player-container">
    <button @click="$emit('close')">&times; Close</button>
    <video controls autoplay :src="video.url" class="video-player">
      Your browser does not support the video tag.
    </video>
  </div>
</template>

<script>
export default {
  name: "VideoPlayer",
  props: {
    video: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    console.log("Video URL in VideoPlayer:", this.video.url); // Debug: Ensure URL is passed correctly
  },
};
</script>

<style scoped>
.video-player-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #000;
  padding: 20px;
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 100;
}
.video-player {
  width: 80vw; /* Adjust size based on your layout */
  max-height: 80vh;
}
</style>
