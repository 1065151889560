<template>
  <div class="video-overlay">
    <div class="profile-section">
      <div class="profile-details">
        <!-- First Line: Profile Image, Username, Follow Button -->
        <div class="user-info">
                   <img :src="profileImageUrl" alt="Profile" class="profile-image" />

          <span class="username">{{ username }}</span>
          <button class="follow-button">Follow</button>
        </div>
        <!-- Second Line: Description (truncated if too long) -->
        <!-- <p class="description">{{ truncatedDescription }}</p> -->
        <!-- Third Line: OnlyFans and Link Button -->
        <div class="third-line">
                    <button class="onlyfans-button" @click="openLink(onlyFansUrl)">

                      <font-awesome-icon :icon="['fas', 'link']" class="link-icon" />
          </button>

          <button class="onlyfans-button" @click="openLink(onlyFansUrl)">
            <img src="@/assets/oficon2.png" alt="OnlyFans" class="onlyfans-icon" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faLink);

export default {
  components: {
    FontAwesomeIcon,
  },
  props: {
    username: {
      type: String,
      default: '******',
      required: true,
    },
    profileImageUrl: {
      type: String,
      default: '', // Provide a default value if needed
    },
    description: {
      type: String,
      default: '', // Provide a default value if needed
    },
    onlyFansUrl: {
      type: String,
      default: '', // Provide a default value if needed
    },
    tags: {
      type: Array,
      default: () => [], // Default to an empty array if no tags are passed
    },
  },
  computed: {
    truncatedDescription() {
      const maxLength = 30; // Maximum characters before truncating
      return this.description.length > maxLength
        ? this.description.substring(0, maxLength) + '...'
        : this.description;
    }
  },
  methods: {
    openLink(url) {
      window.open(url, "_blank");
    },
  },
};
</script>


<style scoped>
.video-overlay {
  position: absolute;
  bottom: 12%;
  left: 7%;
  display: flex;
  align-items: center;
  /* background-color: rgba(46, 46, 46, 0.6); */
  padding: 10px;
  border-radius: 10px;
  max-width: 250px; /* Set maximum width to control the overlay width */
  width: auto; /* Set the width to auto or adjust as necessary */
}

.profile-section {
  display: flex;
  align-items: center;
}

.profile-image {
  width: 45px; /* Profile image size */
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.profile-details {
  display: flex;
  flex-direction: column;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 5px; /* Adjusts the spacing between profile image, username, and follow button */
}

.username {
  color: white;
  font-weight: bold;
}

.follow-button {
  background: transparent;
  border: 1px solid white;
  color: white;
  border-radius: 20px;
  padding: 4px 10px;
  font-size: 12px;
  cursor: pointer;
}

.description {
  color: white;
  margin: 5px 0;
  font-size: 14px;
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden;
  text-overflow: ellipsis; /* Add ellipsis (...) if text is too long */
}

.third-line {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align buttons to the start of the line */
  gap: 10px; 
  margin-top: 5px; 

}

.onlyfans-button {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0; /* Remove any extra margin */
  display: flex;
  align-items: center;
}

.onlyfans-icon {
  width: 31px; /* Adjust size for consistency */
  height: 22px;
   /* padding-left: 10px; */

  /* padding-right: ; */
}
.link-icon {
  color: #ffffff;
  font-size: 20px; /* Adjust to match the OnlyFans icon size */
  margin: 0; /* Remove margins to align better */
  display: flex;
  align-items: center;
}
</style>
