<template>
  <div class="container">
    <button class="close-premium-button" @click="closePremiumPage">&times;</button>

    <!-- Short-form content section -->
    <!-- <div class="shorts-container">
      <h2 class="section-title" v-if="premium">Shorts</h2>
      <div class="shorts-scroll" ref="scrollContainer" @scroll="handleScroll">
        <div class="short-video" v-for="short in shortVideos" :key="short.id">
          <video class="video">
            <source :src="short.url" type="video/mp4" />
          </video>
          <button
            class="overlay-button"
            @click="selectVideo(short)"
          ></button>
        </div>
      </div>
      <button class="nav-button left" @click="scrollLeft">&lt;</button>
      <button class="nav-button right" @click="scrollRight">&gt;</button>
    </div> -->

    <!-- Long-form content grid -->
    <h2 class="section-title" v-if="premium">Videos</h2>
    <section class="video-grid" v-if="premium">
      <div v-for="video in longVideos" :key="video.id" class="video-item">
        <div class="video-wrapper">
          <video width="250">
            <source :src="video.s3String" type="video/mp4" />
            Your bro does not support the video tag.
          </video>
          <button
            class="overlay-button"
            @click="selectVideo(video)"
          ></button>
        </div>
      </div>
    </section>
    <button class="load-more-button" @click="loadMoreVideos" v-if="premium">
      Load More
    </button>

    <!-- Video player -->
    <VideoPlayer
      v-if="selectedVideo"
      :video="selectedVideo"
      @close="closeVideoPlayer"
    />
  </div>
</template>
<script>
import axios from "axios";
import { mapState } from "vuex";
import VideoPlayer from "./VideoPlayer.vue";

export default {
  name: "VideoGridComponent",
  props: {
    // shortVideos: Array,
    // longVideos: Array,
    premium: Boolean,
  },
  data() {
    return {
      // localShortVideos: [...this.shortVideos],
      currentPage: 1,
      loading: false,
      selectedVideo: null,
      longVideos:[],
      
    };
  },
  components: {
    VideoPlayer,
  },
  computed: {
     ...mapState(["videos", "currentPage"]),
  },
  created() {
    // this.$store.dispatch("fetchVideos");
    this.fetchVideos();
  },
  methods: {
    async fetchVideos() {
        try {
            const response = await axios.get(`https://www.fapshort.com/premium_videos?page=${this.currentPage}&limit=80`, {
                headers: {
                    Accept: "application/json",
                },
            });
            console.log("Response data:", response.data); // Log the response data
            this.longVideos.push(...response.data);
            console.log("Long videos:", this.longVideos);
        } catch (error) {
            console.error("There was an error fetching the videos:", error);
        }
    },
    async loadMoreVideos() {
        if (this.loading) return;
        this.loading = true;
        try {
            this.currentPage++;
            console.log(`Fetching page ${this.currentPage}`);
            const response = await axios.get(
                `https://www.fapshort.com/premium_videos?page=${this.currentPage}&limit=40`,
                {
                    headers: {
                        Accept: "application/json",
                    },
                }
            );
            console.log("Response data:", response.data);
            this.longVideos.push(...response.data); // Append the new videos
        } catch (error) {
            console.error("There was an error fetching the videos:", error);
        } finally {
            this.loading = false;
        }
    },
    selectVideo(video) {
        this.selectedVideo = video;
    },
    closeVideoPlayer() {
        this.selectedVideo = null;
    },
    closePremiumPage() {
        this.$emit("close-premium");
    },
}

};
</script>

<style scoped>
.container {
  background-color: rgba(0, 0, 0, 1);
  margin: auto;
}
.close-premium-button {
  position: fixed;
  top: 20px;
  right: 20px;
  background: transparent;
  border: none;
  color: white;
  font-size: 2em;
  cursor: pointer;
  z-index: 10;
}

.section-title {
  text-align: left;
  padding: 0 1rem;
}

.video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
  padding: 1px;
}
.video-item {
  position: relative;
  width: 100%;
  cursor: pointer;
}
.video-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}
video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.overlay-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.01);
  color: white;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease;
}
.overlay-button:hover {
  background: rgba(0, 0, 0, 0.1);
}
</style>