<template>
  <div class="login-overlay" @click.self="closeForm">
    <div class="login-container" v-if="!isSignUpSuccessful && !isLoginSuccessful">
      <h1 v-if="!isSignUp">Log In</h1>
      <h1 v-else>Sign Up</h1>
      <div class="login-content">
        <h2>Join the Future of Porn</h2>
        <p>
          FapShort is a mobile-optimized app with an intelligent feed algorithm that learns what you like and keeps giving you more of it.
        </p>
        <div class="login-links">
          <a href="#" @click.prevent="toggleForm">{{ isSignUp ? 'Already have an account? Log in.' : 'Sign up here' }}</a>
          <a href="#">Learn about us</a>
        </div>
        <form @submit.prevent="handleSubmit">
          <div class="input-group">
            <label for="email">Email</label>
            <input type="email" id="email" v-model="email" :class="{ invalid: emailError }" @blur="validateEmail" required />
            <span v-if="emailError" class="error-message">{{ emailError }}</span>
          </div>

          <!-- Username Field - Only Show on Signup -->
          <div class="input-group" v-if="isSignUp">
            <label for="username">Username</label>
            <input type="text" id="username" v-model="username" required />
          </div>

          <!-- OnlyFans Link - Only Show on Signup -->
          <div class="input-group" v-if="isSignUp">
            <label for="onlyfans_link">OnlyFans Link</label>
            <input type="url" id="onlyfans_link" v-model="onlyfansLink" />
          </div>

          <!-- Profile Image - Only Show on Signup -->
          <!-- <div class="input-group" v-if="isSignUp">
            <label for="profile_image">Profile Image</label>
            <input type="file" id="profile_image" @change="handleProfileImageUpload" />
          </div> -->

          <div class="input-group">
            <label for="password">Password</label>
            <input type="password" id="password" v-model="password" :class="{ invalid: passwordError }" @blur="validatePassword" required />
            <span v-if="passwordError" class="error-message">{{ passwordError }}</span>
          </div>

          <!-- Confirm Password - Only Show on Signup -->
          <div class="input-group" v-if="isSignUp">
            <label for="password_confirmation">Confirm Password</label>
            <input type="password" id="password_confirmation" v-model="passwordConfirmation" :class="{ invalid: passwordConfirmationError }" @blur="validatePasswordConfirmation" required />
            <span v-if="passwordConfirmationError" class="error-message">{{ passwordConfirmationError }}</span>
          </div>

          <button type="submit" class="login-button" :disabled="isSubmitting">{{ isSignUp ? 'Sign Up' : 'Log In' }}</button>
         </form>
        <p v-if="message" class="info-message">{{ message }}</p>
      </div>
    </div>
    <div class="congratulations-message" v-if="isSignUpSuccessful || isLoginSuccessful">
      <h1>{{ isSignUpSuccessful ? 'Congratulations!' : 'Welcome Back!' }}</h1>
      <p>{{ isSignUpSuccessful ? 'You have successfully signed up for FapShort.' : 'You have successfully logged in to FapShort.' }}</p>
      <button @click="closeForm" class="close-button">Close</button>
    </div>
  </div>
</template>

<script>
// import axios from 'axios';

export default {
    emits: ['submit-form', 'close'], 
  data() {
    return {
      email: '',
      password: '',
      passwordConfirmation: '',
      username: '',
      description: '',
      onlyfansLink: '',
      otherLinks: '',
      profileImage: null,
      isSignUp: false,
      emailError: '',
      passwordError: '',
      passwordConfirmationError: '',
      isSubmitting: false,
      message: '',
      isSignUpSuccessful: false,
      isLoginSuccessful: false,
    };
  },
  methods: {
    closeForm() {
      this.$emit('close');
    },
    toggleForm() {
      this.isSignUp = !this.isSignUp;
    },
    validateEmail() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.emailError = !emailPattern.test(this.email) ? 'Please enter a valid email address.' : '';
    },
    validatePassword() {
      const passwordPattern = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{6,}$/;
      this.passwordError = !passwordPattern.test(this.password)
        ? 'Password must be at least 6 characters long, contain at least one uppercase letter, one number, and one special character.'
        : '';
    },
    validatePasswordConfirmation() {
      this.passwordConfirmationError =
        this.password !== this.passwordConfirmation ? 'Passwords do not match.' : '';
    },
    handleProfileImageUpload(event) {
      this.profileImage = event.target.files[0];
    },
    handleSubmit() {
      // Validate the form
      this.validateEmail();
      this.validatePassword();
      if (this.isSignUp) {
        this.validatePasswordConfirmation();
      }

      // Emit form data to App.vue if no errors
      if (!this.emailError && !this.passwordError && !this.passwordConfirmationError) {
        const formData = {
          email: this.email,
          password: this.password,
          username: this.username,
          description: this.description,
          onlyfansLink: this.onlyfansLink,
          otherLinks: this.onlyfansLink || '',
          profileImage: this.profileImage,
          passwordConfirmation: this.passwordConfirmation,
          isSignUp: this.isSignUp,
        };
        console.log('emitting to app.vue');
        this.$emit('submit-form', formData);
        this.closeForm();
      }
    },
  },
};
</script>

<style scoped>
/* Add your styles here */
.login-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.login-container,
.congratulations-message {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  max-width: 90%;
  text-align: center;
}

.congratulations-message h1 {
  margin-bottom: 20px;
}

.congratulations-message p {
  margin-bottom: 20px;
}

.login-content h2 {
  margin-bottom: 10px;
}

.login-content p {
  margin-bottom: 20px;
}

.login-links {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.login-links a {
  color: #007bff;
  text-decoration: none;
}

.input-group {
  margin-bottom: 15px;
  text-align: left;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
}

.input-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.input-group input.invalid {
  border-color: red;
}

.error-message {
  color: red;
  font-size: 12px;
}

.info-message {
  color: green;
  font-size: 14px;
}

.forgot-password {
  display: block;
  margin-bottom: 20px;
  color: #007bff;
  text-decoration: none;
  text-align: right;
}

.login-button {
  background: #ff69b4;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 20px;
}

.login-button:disabled {
  background: #ddd;
  cursor: not-allowed;
}

.agreement {
  font-size: 12px;
  color: #666;
}

.agreement a {
  color: #007bff;
  text-decoration: none;
}

.close-button {
  background: #ff69b4;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}
</style>
